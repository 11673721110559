import Links from "./Contact"

export default function Repo() {
    return (
        <div className="footer">
            <div className="title-footer">
                Built & Designed by Sami Atoui
            </div>
            <Links />
        </div>
    )
}